<template>
  <svg viewBox="0 0 585 626" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>bagulho 2</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="left-shape-linearGradient-1">
        <stop :stop-color="colorA" offset="0%"></stop>
        <stop :stop-color="colorB" offset="100%"></stop>
      </linearGradient>
      <path d="M0,0 C15.3753394,8.13958825 28.7086011,15.746234 39.9997851,22.8199371 C56.3513222,33.0638513 74.9175696,47.8115503 99.0180235,62.119256 C172.613256,105.810505 256.868586,95.4699237 329.006886,54.9313426 C357.127059,39.1290305 395.165644,28.8013266 435.549751,34.0098481 C503.848491,42.8186467 577.362341,89.469361 584.12083,184.683995 C590.407524,273.251892 536.124067,417.648197 362.190023,525.238116 C246.233994,596.964728 125.503986,630.367028 0,625.445017 L0,0 Z" id="left-shape-path-2"></path>
    </defs>
    <g id="left-shape-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.15">
      <g id="left-shape-Desktop-/-Trial-/-1.0---Login" transform="translate(0.000000, -398.000000)">
        <g id="left-shape-bagulho-2" transform="translate(0.000000, 398.000000)">
          <mask id="left-shape-mask-3" fill="white">
            <use xlink:href="#left-shape-path-2"></use>
          </mask>
          <use id="left-shape-Mask" fill="url(#left-shape-linearGradient-1)" xlink:href="#left-shape-path-2"></use>
          <g id="left-shape-Banner" opacity="0.3" style="mix-blend-mode: overlay;" mask="url(#left-shape-mask-3)">
            <g transform="translate(-105.000000, -287.000000)" id="left-shape-Shape">
              <path d="M636.8,887.2 L637.8,882.4 C637.9,882 638.3,881.7 638.7,881.8 L643.5,882.8 C643.9,882.9 644.2,883.3 644.1,883.7 L643.1,888.5 C643,888.9 642.6,889.2 642.2,889.1 L637.4,888.1 C637,888.1 636.7,887.6 636.8,887.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M568.9,880.4 L566.3,872.5 C565.9,871.2 566.6,869.8 567.9,869.3 L575.8,866.7 C577.1,866.3 578.5,867 579,868.3 L581.6,876.2 C582,877.5 581.3,878.9 580,879.4 L572.1,882 C570.8,882.4 569.4,881.7 568.9,880.4 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M685.3,857.6 L690.4,844.4 C691.6,841.2 695.3,839.6 698.5,840.8 L711.7,845.9 C714.9,847.1 716.5,850.8 715.3,854 L710.2,867.2 C709,870.4 705.3,872 702.1,870.8 L688.9,865.7 C685.7,864.4 684.1,860.8 685.3,857.6 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M676.6,606.9 L678.7,596.6 C679.6,592.2 683.9,589.4 688.3,590.3 L698.6,592.4 C703,593.3 705.8,597.6 704.9,602 L702.8,612.3 C701.9,616.7 697.6,619.5 693.2,618.6 L682.9,616.5 C678.5,615.5 675.7,611.2 676.6,606.9 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M656.1,693.1 L663.8,686 C667.1,683 672.2,683.2 675.2,686.4 L682.3,694.1 C685.3,697.4 685.1,702.5 681.9,705.5 L674.2,712.6 C670.9,715.6 665.8,715.4 662.8,712.2 L655.7,704.5 C652.6,701.3 652.8,696.2 656.1,693.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M583.1,624.5 L579,614.9 C577.2,610.8 579.1,606 583.3,604.3 L592.9,600.2 C597,598.4 601.8,600.3 603.5,604.5 L607.6,614.1 C609.4,618.2 607.5,623 603.3,624.7 L593.7,628.8 C589.6,630.5 584.8,628.6 583.1,624.5 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M485.8,824.7 L489.5,814.9 C491.1,810.7 495.8,808.6 500,810.2 L509.8,813.9 C514,815.5 516.1,820.2 514.5,824.4 L510.8,834.2 C509.2,838.4 504.5,840.5 500.3,838.9 L490.5,835.2 C486.3,833.5 484.2,828.9 485.8,824.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M609.3,826.1 L628.6,818.3 C630.1,817.7 631.8,818.4 632.4,819.9 L640.2,839.2 C640.8,840.7 640.1,842.4 638.6,843 L619.3,850.8 C617.8,851.4 616.1,850.7 615.5,849.2 L607.7,829.9 C607.1,828.4 607.8,826.7 609.3,826.1 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M484.8,611.4 L491.8,603.6 C494.8,600.3 499.9,600 503.2,602.9 L511,609.9 C514.3,612.9 514.6,618 511.7,621.3 L504.7,629.1 C501.7,632.4 496.6,632.7 493.3,629.8 L485.5,622.8 C482.2,619.9 481.9,614.8 484.8,611.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M497.4,562.3 L498.4,557.5 C498.5,557.1 498.9,556.8 499.3,556.9 L504.1,557.9 C504.5,558 504.8,558.4 504.7,558.8 L503.7,563.6 C503.6,564 503.2,564.3 502.8,564.2 L498,563.2 C497.5,563.1 497.3,562.7 497.4,562.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M530.4,689.2 L534,685.8 C534.3,685.5 534.8,685.5 535.1,685.8 L538.5,689.4 C538.8,689.7 538.8,690.2 538.5,690.5 L534.9,693.9 C534.6,694.2 534.1,694.2 533.8,693.9 L530.4,690.3 C530,690 530,689.5 530.4,689.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M423.1,654.6 L421.2,650.1 C421,649.7 421.2,649.2 421.6,649 L426.1,647.1 C426.5,646.9 427,647.1 427.2,647.5 L429.1,652 C429.3,652.4 429.1,652.9 428.7,653.1 L424.2,655 C423.8,655.2 423.3,655 423.1,654.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M553.5,818.2 L555.3,813.6 C555.5,813.2 555.9,813 556.3,813.1 L560.9,814.9 C561.3,815.1 561.5,815.5 561.4,815.9 L559.6,820.5 C559.4,820.9 559,821.1 558.6,821 L554,819.2 C553.6,819 553.4,818.6 553.5,818.2 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M588.9,768.6 L593.5,766.7 C593.9,766.5 594.4,766.7 594.5,767.1 L596.4,771.7 C596.6,772.1 596.4,772.6 596,772.7 L591.4,774.6 C591,774.8 590.5,774.6 590.4,774.2 L588.5,769.6 C588.3,769.2 588.5,768.8 588.9,768.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M650,648.2 L653.3,644.5 C653.6,644.2 654.1,644.1 654.4,644.4 L658.1,647.7 C658.4,648 658.5,648.5 658.2,648.8 L654.9,652.5 C654.6,652.8 654.1,652.9 653.8,652.6 L650.1,649.3 C649.8,649 649.8,648.5 650,648.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M494,756.1 L490.1,748.7 C489.4,747.5 489.9,746 491.1,745.3 L498.5,741.4 C499.7,740.7 501.2,741.2 501.9,742.4 L505.8,749.8 C506.5,751 506,752.5 504.8,753.2 L497.4,757.1 C496.2,757.7 494.7,757.3 494,756.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M430.4,778.6 L426.8,771 C426.2,769.7 426.7,768.3 428,767.7 L435.6,764.1 C436.9,763.5 438.3,764 438.9,765.3 L442.5,772.9 C443.1,774.2 442.6,775.6 441.3,776.2 L433.7,779.8 C432.5,780.4 431,779.8 430.4,778.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M453,684.1 L461.3,684.7 C462.7,684.8 463.7,686 463.6,687.4 L463,695.7 C462.9,697.1 461.7,698.1 460.3,698 L452,697.4 C450.6,697.3 449.6,696.1 449.7,694.7 L450.3,686.4 C450.5,685 451.7,683.9 453,684.1 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M429.5,555.5 L426.9,547.6 C426.5,546.3 427.2,544.9 428.5,544.4 L436.4,541.8 C437.7,541.4 439.1,542.1 439.6,543.4 L442.2,551.3 C442.6,552.6 441.9,554 440.6,554.5 L432.7,557.1 C431.3,557.5 429.9,556.8 429.5,555.5 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M590.7,684.3 L597.3,681 C598.8,680.3 600.6,680.9 601.3,682.3 L604.6,688.9 C605.3,690.4 604.7,692.2 603.3,692.9 L596.7,696.2 C595.2,696.9 593.4,696.3 592.7,694.9 L589.4,688.3 C588.6,686.9 589.2,685.1 590.7,684.3 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M659.3,796 L657.6,787.8 C657.3,786.4 658.2,785.1 659.5,784.8 L667.7,783.1 C669.1,782.8 670.4,783.7 670.7,785 L672.4,793.2 C672.7,794.6 671.8,795.9 670.5,796.2 L662.3,797.9 C660.9,798.2 659.6,797.3 659.3,796 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M545.9,532.6 L551,519.4 C552.2,516.2 555.9,514.6 559.1,515.8 L572.3,520.9 C575.5,522.1 577.1,525.8 575.9,529 L570.8,542.2 C569.6,545.4 565.9,547 562.7,545.8 L549.5,540.7 C546.3,539.5 544.7,535.9 545.9,532.6 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M537.1,281.9 L539.2,271.6 C540.1,267.2 544.4,264.4 548.8,265.3 L559.1,267.4 C563.5,268.3 566.3,272.6 565.4,277 L563.3,287.3 C562.4,291.7 558.1,294.5 553.7,293.6 L543.4,291.5 C539,290.6 536.2,286.3 537.1,281.9 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M516.7,368.2 L524.4,361.1 C527.7,358.1 532.8,358.3 535.8,361.5 L542.9,369.2 C545.9,372.5 545.7,377.6 542.5,380.6 L534.8,387.7 C531.5,390.7 526.4,390.5 523.4,387.3 L516.3,379.6 C513.2,376.3 513.4,371.2 516.7,368.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M443.6,299.5 L439.5,289.9 C437.7,285.8 439.6,281 443.8,279.3 L453.4,275.2 C457.5,273.4 462.3,275.3 464,279.5 L468.1,289.1 C469.9,293.2 468,298 463.8,299.7 L454.2,303.8 C450.2,305.6 445.4,303.6 443.6,299.5 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M346.3,499.7 L350,489.9 C351.6,485.7 356.3,483.6 360.5,485.2 L370.3,488.9 C374.5,490.5 376.6,495.2 375,499.4 L371.3,509.2 C369.7,513.4 365,515.5 360.8,513.9 L351,510.2 C346.8,508.6 344.7,503.9 346.3,499.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M469.9,501.2 L489.2,493.4 C490.7,492.8 492.4,493.5 493,495 L500.8,514.3 C501.4,515.8 500.7,517.5 499.2,518.1 L479.9,525.9 C478.4,526.5 476.7,525.8 476.1,524.3 L468.3,505 C467.7,503.4 468.4,501.8 469.9,501.2 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M345.4,286.5 L352.4,278.7 C355.4,275.4 360.5,275.1 363.8,278 L371.6,285 C374.9,288 375.2,293.1 372.3,296.4 L365.3,304.2 C362.3,307.5 357.2,307.8 353.9,304.9 L346.1,297.9 C342.7,294.9 342.4,289.8 345.4,286.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M390.9,364.3 L394.5,360.9 C394.8,360.6 395.3,360.6 395.6,360.9 L399,364.5 C399.3,364.8 399.3,365.3 399,365.6 L395.4,369 C395.1,369.3 394.6,369.3 394.3,369 L390.9,365.4 C390.6,365.1 390.6,364.6 390.9,364.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M283.7,329.6 L281.8,325.1 C281.6,324.7 281.8,324.2 282.2,324 L286.7,322.1 C287.1,321.9 287.6,322.1 287.8,322.5 L289.7,327 C289.9,327.4 289.7,327.9 289.3,328.1 L284.8,330 C284.3,330.2 283.9,330 283.7,329.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M414.1,493.2 L415.9,488.6 C416.1,488.2 416.5,488 416.9,488.1 L421.5,489.9 C421.9,490.1 422.1,490.5 422,490.9 L420.2,495.5 C420,495.9 419.6,496.1 419.2,496 L414.6,494.2 C414.1,494.1 413.9,493.6 414.1,493.2 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M449.4,443.7 L454,441.8 C454.4,441.6 454.9,441.8 455,442.2 L456.9,446.8 C457.1,447.2 456.9,447.7 456.5,447.8 L451.9,449.7 C451.5,449.9 451,449.7 450.9,449.3 L449,444.7 C448.8,444.3 449,443.8 449.4,443.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M510.6,323.3 L513.9,319.6 C514.2,319.3 514.7,319.2 515,319.5 L518.7,322.8 C519,323.1 519.1,323.6 518.8,323.9 L515.5,327.6 C515.2,327.9 514.7,328 514.4,327.7 L510.7,324.4 C510.3,324.1 510.3,323.6 510.6,323.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M354.6,431.1 L350.7,423.7 C350,422.5 350.5,421 351.7,420.3 L359.1,416.4 C360.3,415.7 361.8,416.2 362.5,417.4 L366.4,424.8 C367.1,426 366.6,427.5 365.4,428.2 L358,432.1 C356.8,432.8 355.2,432.3 354.6,431.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M290.9,453.6 L287.3,446 C286.7,444.7 287.2,443.3 288.5,442.7 L296.1,439.1 C297.4,438.5 298.8,439 299.4,440.3 L303,447.9 C303.6,449.2 303.1,450.6 301.8,451.2 L294.2,454.8 C293,455.4 291.5,454.9 290.9,453.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M313.6,359.1 L321.9,359.7 C323.3,359.8 324.3,361 324.2,362.4 L323.6,370.7 C323.5,372.1 322.3,373.1 320.9,373 L312.6,372.4 C311.2,372.3 310.2,371.1 310.3,369.7 L310.9,361.4 C311,360 312.2,359 313.6,359.1 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M451.2,359.4 L457.8,356.1 C459.3,355.4 461.1,356 461.8,357.4 L465.1,364 C465.8,365.5 465.2,367.3 463.8,368 L457.2,371.3 C455.7,372 453.9,371.4 453.2,370 L449.9,363.4 C449.1,361.9 449.7,360.1 451.2,359.4 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M519.8,471 L518.1,462.8 C517.8,461.4 518.7,460.1 520,459.8 L528.2,458.1 C529.6,457.8 530.9,458.7 531.2,460 L532.9,468.2 C533.2,469.6 532.3,470.9 531,471.2 L522.8,472.9 C521.4,473.2 520.1,472.4 519.8,471 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M583.8,291.3 L581.2,283.4 C580.8,282.1 581.5,280.7 582.8,280.2 L590.7,277.6 C592,277.2 593.4,277.9 593.9,279.2 L596.5,287.1 C596.9,288.4 596.2,289.8 594.9,290.3 L587,292.9 C585.7,293.3 584.3,292.6 583.8,291.3 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M512.4,917.2 L514.5,906.9 C515.4,902.5 519.7,899.7 524.1,900.6 L534.4,902.7 C538.8,903.6 541.6,907.9 540.7,912.3 L538.6,922.6 C537.7,927 533.4,929.8 529,928.9 L518.7,926.8 C514.3,925.8 511.5,921.5 512.4,917.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M333.2,872.6 L334.2,867.8 C334.3,867.4 334.7,867.1 335.1,867.2 L339.9,868.2 C340.3,868.3 340.6,868.7 340.5,869.1 L339.5,873.9 C339.4,874.3 339,874.6 338.6,874.5 L333.8,873.5 C333.3,873.4 333.1,873 333.2,872.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M265.3,865.8 L262.7,857.9 C262.3,856.6 263,855.2 264.3,854.7 L272.2,852.1 C273.5,851.7 274.9,852.4 275.4,853.7 L278,861.6 C278.4,862.9 277.7,864.3 276.4,864.8 L268.5,867.4 C267.1,867.8 265.7,867.1 265.3,865.8 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M381.7,842.9 L386.8,829.7 C388,826.5 391.7,824.9 394.9,826.1 L408.1,831.2 C411.3,832.4 412.9,836.1 411.7,839.3 L406.6,852.5 C405.4,855.7 401.7,857.3 398.5,856.1 L385.3,851 C382.1,849.8 380.5,846.1 381.7,842.9 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M559.1,926.5 L556.5,918.6 C556.1,917.3 556.8,915.9 558.1,915.4 L566,912.8 C567.3,912.4 568.7,913.1 569.2,914.4 L571.8,922.3 C572.2,923.6 571.5,925 570.2,925.5 L562.3,928.1 C560.9,928.6 559.5,927.9 559.1,926.5 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M372.9,592.2 L375,582 C375.9,577.6 380.2,574.8 384.6,575.7 L394.9,577.8 C399.3,578.7 402.1,583 401.2,587.4 L399.1,597.7 C398.2,602.1 393.9,604.9 389.5,604 L379.2,601.9 C374.8,600.9 372,596.6 372.9,592.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M352.5,678.5 L360.2,671.4 C363.5,668.4 368.6,668.6 371.6,671.8 L378.7,679.5 C381.7,682.8 381.5,687.9 378.3,690.9 L370.6,698 C367.3,701 362.2,700.8 359.2,697.6 L352.1,689.9 C349,686.6 349.2,681.5 352.5,678.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M279.4,609.8 L275.3,600.2 C273.5,596.1 275.4,591.3 279.6,589.6 L289.2,585.5 C293.3,583.7 298.1,585.6 299.8,589.8 L303.9,599.4 C305.7,603.5 303.8,608.3 299.6,610 L290,614.1 C286,615.8 281.2,613.9 279.4,609.8 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M182.1,810 L185.8,800.2 C187.4,796 192.1,793.9 196.3,795.5 L206.1,799.2 C210.3,800.8 212.4,805.5 210.8,809.7 L207.1,819.5 C205.5,823.7 200.8,825.8 196.6,824.2 L186.8,820.5 C182.6,818.9 180.5,814.2 182.1,810 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M305.7,811.4 L325,803.6 C326.5,803 328.2,803.7 328.8,805.2 L336.6,824.5 C337.2,826 336.5,827.7 335,828.3 L315.7,836.1 C314.2,836.7 312.5,836 311.9,834.5 L304.1,815.2 C303.5,813.7 304.2,812 305.7,811.4 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M181.2,596.8 L188.2,589 C191.2,585.7 196.3,585.4 199.6,588.3 L207.4,595.3 C210.7,598.3 211,603.4 208.1,606.7 L201.1,614.5 C198.1,617.8 193,618.1 189.7,615.2 L181.9,608.2 C178.5,605.2 178.2,600.1 181.2,596.8 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M193.7,547.6 L194.7,542.8 C194.8,542.4 195.2,542.1 195.6,542.2 L200.4,543.2 C200.8,543.3 201.1,543.7 201,544.1 L200,548.9 C199.9,549.3 199.5,549.6 199.1,549.5 L194.3,548.5 C193.9,548.5 193.6,548.1 193.7,547.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M226.7,674.6 L230.3,671.2 C230.6,670.9 231.1,670.9 231.4,671.2 L234.8,674.8 C235.1,675.1 235.1,675.6 234.8,675.9 L231.2,679.3 C230.9,679.6 230.4,679.6 230.1,679.3 L226.7,675.7 C226.4,675.4 226.4,674.9 226.7,674.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M119.5,639.9 L117.6,635.4 C117.4,635 117.6,634.5 118,634.3 L122.5,632.4 C122.9,632.2 123.4,632.4 123.6,632.8 L125.5,637.3 C125.7,637.7 125.5,638.2 125.1,638.4 L120.6,640.3 C120.1,640.5 119.6,640.3 119.5,639.9 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M249.9,803.5 L251.7,798.9 C251.9,798.5 252.3,798.3 252.7,798.4 L257.3,800.2 C257.7,800.4 257.9,800.8 257.8,801.2 L256,805.8 C255.8,806.2 255.4,806.4 255,806.3 L250.4,804.5 C249.9,804.4 249.7,803.9 249.9,803.5 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M285.2,754 L289.8,752.1 C290.2,751.9 290.7,752.1 290.8,752.5 L292.7,757.1 C292.9,757.5 292.7,758 292.3,758.1 L287.7,760 C287.3,760.2 286.8,760 286.7,759.6 L284.8,755 C284.6,754.6 284.8,754.1 285.2,754 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M346.4,633.6 L349.7,629.9 C350,629.6 350.5,629.5 350.8,629.8 L354.5,633.1 C354.8,633.4 354.9,633.9 354.6,634.2 L351.3,637.9 C351,638.2 350.5,638.3 350.2,638 L346.5,634.7 C346.1,634.4 346.1,633.9 346.4,633.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M190.4,741.4 L186.5,734 C185.8,732.8 186.3,731.3 187.5,730.6 L194.9,726.7 C196.1,726 197.6,726.5 198.3,727.7 L202.2,735.1 C202.9,736.3 202.4,737.8 201.2,738.5 L193.8,742.4 C192.6,743.1 191,742.6 190.4,741.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M126.7,763.9 L123.1,756.3 C122.5,755 123,753.6 124.3,753 L131.9,749.4 C133.2,748.8 134.6,749.3 135.2,750.6 L138.8,758.2 C139.4,759.5 138.9,760.9 137.6,761.5 L130,765.1 C128.8,765.7 127.3,765.2 126.7,763.9 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M149.4,669.4 L157.7,670 C159.1,670.1 160.1,671.3 160,672.7 L159.4,681 C159.3,682.4 158.1,683.4 156.7,683.3 L148.4,682.7 C147,682.6 146,681.4 146.1,680 L146.7,671.7 C146.8,670.3 148,669.3 149.4,669.4 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M125.8,540.8 L123.2,532.9 C122.8,531.6 123.5,530.2 124.8,529.7 L132.7,527.1 C134,526.7 135.4,527.4 135.9,528.7 L138.5,536.6 C138.9,537.9 138.2,539.3 136.9,539.8 L129,542.4 C127.7,542.9 126.3,542.1 125.8,540.8 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M287,669.7 L293.6,666.4 C295.1,665.7 296.9,666.3 297.6,667.7 L300.9,674.3 C301.6,675.8 301,677.6 299.6,678.3 L293,681.6 C291.5,682.3 289.7,681.7 289,680.3 L285.7,673.7 C284.9,672.2 285.5,670.4 287,669.7 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M355.6,781.3 L353.9,773.1 C353.6,771.7 354.5,770.4 355.8,770.1 L364,768.4 C365.4,768.1 366.7,769 367,770.3 L368.7,778.5 C369,779.9 368.1,781.2 366.8,781.5 L358.6,783.2 C357.2,783.5 355.9,782.7 355.6,781.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M242.2,518 L247.3,504.8 C248.5,501.6 252.2,500 255.4,501.2 L268.6,506.3 C271.8,507.5 273.4,511.2 272.2,514.4 L267.1,527.6 C265.9,530.8 262.2,532.4 259,531.2 L245.8,526.1 C242.6,524.8 241,521.2 242.2,518 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M419.6,601.6 L417,593.7 C416.6,592.4 417.3,591 418.6,590.5 L426.5,587.9 C427.8,587.5 429.2,588.2 429.7,589.5 L432.3,597.4 C432.7,598.7 432,600.1 430.7,600.6 L422.8,603.2 C421.5,603.6 420.1,602.9 419.6,601.6 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M213,353.5 L220.7,346.4 C224,343.4 229.1,343.6 232.1,346.8 L239.2,354.5 C242.2,357.8 242,362.9 238.8,365.9 L231.1,373 C227.8,376 222.7,375.8 219.7,372.6 L212.6,364.9 C209.5,361.7 209.7,356.6 213,353.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M140,284.9 L135.9,275.3 C134.1,271.2 136,266.4 140.2,264.7 L149.8,260.6 C153.9,258.8 158.7,260.7 160.4,264.9 L164.5,274.5 C166.3,278.6 164.4,283.4 160.2,285.1 L150.6,289.2 C146.5,290.9 141.7,289 140,284.9 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M166.2,486.5 L185.5,478.7 C187,478.1 188.7,478.8 189.3,480.3 L197.1,499.6 C197.7,501.1 197,502.8 195.5,503.4 L176.2,511.2 C174.7,511.8 173,511.1 172.4,509.6 L164.6,490.3 C164,488.8 164.7,487.1 166.2,486.5 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M110.4,478.6 L112.2,474 C112.4,473.6 112.8,473.4 113.2,473.5 L117.8,475.3 C118.2,475.5 118.4,475.9 118.3,476.3 L116.5,480.9 C116.3,481.3 115.9,481.5 115.5,481.4 L110.9,479.6 C110.5,479.4 110.3,479 110.4,478.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M145.8,429 L150.4,427.1 C150.8,426.9 151.3,427.1 151.4,427.5 L153.3,432.1 C153.5,432.5 153.3,433 152.9,433.1 L148.3,435 C147.9,435.2 147.4,435 147.3,434.6 L145.4,430 C145.2,429.6 145.4,429.2 145.8,429 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M207,308.6 L210.3,304.9 C210.6,304.6 211.1,304.5 211.4,304.8 L215.1,308.1 C215.4,308.4 215.5,308.9 215.2,309.2 L211.9,312.9 C211.6,313.2 211.1,313.3 210.8,313 L207.1,309.7 C206.7,309.5 206.7,309 207,308.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M147.6,344.7 L154.2,341.4 C155.7,340.7 157.5,341.3 158.2,342.7 L161.5,349.3 C162.2,350.8 161.6,352.6 160.2,353.3 L153.6,356.6 C152.1,357.3 150.3,356.7 149.6,355.3 L146.3,348.7 C145.5,347.3 146.1,345.5 147.6,344.7 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M216.2,456.4 L214.5,448.2 C214.2,446.8 215.1,445.5 216.4,445.2 L224.6,443.5 C226,443.2 227.3,444.1 227.6,445.4 L229.3,453.6 C229.6,455 228.7,456.3 227.4,456.6 L219.2,458.3 C217.8,458.6 216.5,457.7 216.2,456.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M208.7,902.5 L210.8,892.2 C211.7,887.8 216,885 220.4,885.9 L230.7,888 C235.1,888.9 237.9,893.2 237,897.6 L234.9,907.9 C234,912.3 229.7,915.1 225.3,914.2 L215,912.1 C210.6,911.2 207.8,906.9 208.7,902.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M115.2,920.1 L111.1,910.5 C109.3,906.4 111.2,901.6 115.4,899.9 L125,895.8 C129.1,894 133.9,895.9 135.6,900.1 L139.7,909.7 C141.5,913.8 139.6,918.6 135.4,920.3 L125.8,924.4 C121.7,926.1 117,924.2 115.2,920.1 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M78,828.3 L83.1,815.1 C84.3,811.9 88,810.3 91.2,811.5 L104.4,816.6 C107.6,817.8 109.2,821.5 108,824.7 L102.9,837.9 C101.7,841.1 98,842.7 94.8,841.5 L81.6,836.4 C78.4,835.1 76.8,831.5 78,828.3 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M255.4,911.9 L252.8,904 C252.4,902.7 253.1,901.3 254.4,900.8 L262.3,898.2 C263.6,897.8 265,898.5 265.5,899.8 L268.1,907.7 C268.5,909 267.8,910.4 266.5,910.9 L258.6,913.5 C257.3,913.9 255.9,913.2 255.4,911.9 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M116,586.9 L113.4,579 C113,577.7 113.7,576.3 115,575.8 L122.9,573.2 C124.2,572.8 125.6,573.5 126.1,574.8 L128.7,582.7 C129.1,584 128.4,585.4 127.1,585.9 L119.2,588.5 C117.8,589 116.4,588.3 116,586.9 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M644.8,511.7 L648.5,501.9 C650.1,497.7 654.8,495.6 659,497.2 L668.8,500.9 C673,502.5 675.1,507.2 673.5,511.4 L669.8,521.2 C668.2,525.4 663.5,527.5 659.3,525.9 L649.5,522.2 C645.3,520.5 643.2,515.9 644.8,511.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M643.9,298.4 L650.9,290.6 C653.9,287.3 659,287 662.3,289.9 L670.1,296.9 C673.4,299.9 673.7,305 670.8,308.3 L663.8,316.1 C660.8,319.4 655.7,319.7 652.4,316.8 L644.6,309.8 C641.2,306.9 640.9,301.7 643.9,298.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M689.4,376.2 L693,372.8 C693.3,372.5 693.8,372.5 694.1,372.8 L697.5,376.4 C697.8,376.7 697.8,377.2 697.5,377.5 L693.9,380.9 C693.6,381.2 693.1,381.2 692.8,380.9 L689.4,377.3 C689,377 689.1,376.5 689.4,376.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M582.2,341.6 L580.3,337.1 C580.1,336.7 580.3,336.2 580.7,336 L585.2,334.1 C585.6,333.9 586.1,334.1 586.3,334.5 L588.2,339 C588.4,339.4 588.2,339.9 587.8,340.1 L583.3,342 C582.8,342.2 582.3,342 582.2,341.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M653.1,443.1 L649.2,435.7 C648.5,434.5 649,433 650.2,432.3 L657.6,428.4 C658.8,427.7 660.3,428.2 661,429.4 L664.9,436.8 C665.6,438 665.1,439.5 663.9,440.2 L656.5,444.1 C655.3,444.7 653.7,444.3 653.1,443.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M589.4,465.6 L585.8,458 C585.2,456.7 585.7,455.3 587,454.7 L594.6,451.1 C595.9,450.5 597.3,451 597.9,452.3 L601.5,459.9 C602.1,461.2 601.6,462.6 600.3,463.2 L592.7,466.8 C591.5,467.4 590,466.8 589.4,465.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M612.1,371 L620.4,371.6 C621.8,371.7 622.8,372.9 622.7,374.3 L622.1,382.6 C622,384 620.8,385 619.4,384.9 L611.1,384.3 C609.7,384.2 608.7,383 608.8,381.6 L609.4,373.3 C609.5,372 610.7,370.9 612.1,371 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  computed: mapGetters({ colorA: types.SETTINGS_COLOR_A, colorB: types.SETTINGS_COLOR_B })
}
</script>
